import React from "react";
import { FaCheck, FaUsers, FaRegClock } from "react-icons/fa"; // Import des icônes
import logo from "../assets/easyplan.png"; // Assurez-vous que le chemin du logo est correct

function Home() {
  return (
    <div className="home-container">
      {/* Section 1: Header avec logo et titre */}
      <header className="home-header">
        <img src={logo} alt="EasyPlan" className="home-logo" />
        <h1 className="home-title">Bienvenue sur EasyPlan</h1>
        <p className="home-subtitle">
          Simplifiez la gestion de vos employés et plannings avec EasyPlan.
        </p>
      </header>

      {/* Section 2: Fonctionnalités */}
      <section className="features ">
        {/* Fonctionnalités à droite */}
        <div className="feature-list w-1/2">
          <div className="flex flex-col items-start gap-8">
            <div className="feature-card feature-card-gestion-facile">
              <FaCheck className="text-green-500 text-4xl" />
              <h3>Gestion facile</h3>
              <p>Gérez facilement vos employés et leurs plannings.</p>
            </div>
            <div className="feature-card feature-card-gestion-equipes">
              <FaUsers className="text-blue-500 text-4xl" />
              <h3>Gestion des équipes</h3>
              <p>Organisez vos équipes de manière optimale.</p>
            </div>
            <div className="feature-card feature-card-horaires-flexibles">
              <FaRegClock className="text-orange-500 text-4xl" />
              <h3>Horaires flexibles</h3>
              <p>
                Permettez à vos employés de gérer leurs horaires de manière
                flexible.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3: Tarification */}
      <section className="pricing bg-gray-100 py-16">
        <h2 className="text-3xl font-semibold text-center mb-8">Nos Tarifs</h2>
        <div className="pricing-cards flex justify-center gap-8">
          {/* Petit Plan */}
          <div className="pricing-card">
            <h3 className="text-2xl font-semibold mb-4">Petit Plan</h3>
            <p className="text-xl text-gray-700 mb-4">30 €/mois</p>
            <ul className="text-left text-gray-600 mb-6">
              <li>Jusqu'à 5 employés</li>
              <li>Fonctionnalités de base</li>
            </ul>
            <button className="btn-primary bg-green-500 text-white py-2 px-6 rounded-full">
              S'abonner
            </button>
          </div>

          {/* Plan Intermédiaire */}
          <div className="pricing-card">
            <h3 className="text-2xl font-semibold mb-4">Plan Intermédiaire</h3>
            <p className="text-xl text-gray-700 mb-4">80 €/mois</p>
            <ul className="text-left text-gray-600 mb-6">
              <li>Jusqu'à 10 employés</li>
              <li>Fonctionnalités avancées</li>
            </ul>
            <button className="btn-primary bg-blue-500 text-white py-2 px-6 rounded-full">
              S'abonner
            </button>
          </div>

          {/* Plan Avancé */}
          <div className="pricing-card">
            <h3 className="text-2xl font-semibold mb-4">Plan Avancé</h3>
            <p className="text-xl text-gray-700 mb-4">150 €/mois</p>
            <ul className="text-left text-gray-600 mb-6">
              <li>Employés illimités</li>
              <li>Toutes les fonctionnalités</li>
            </ul>
            <button className="btn-primary bg-orange-500 text-white py-2 px-6 rounded-full">
              S'abonner
            </button>
          </div>
        </div>
      </section>

      {/* Section 4: Contact */}
      <section className="contact py-16">
        <div className="text-center">
          <h2 className="text-3xl font-semibold mb-4">Contactez-nous</h2>
          <p className="mb-6">
            Pour toute question, n'hésitez pas à nous contacter.
          </p>
          <button className="btn-primary bg-gray-800 text-white py-2 px-6 rounded-full">
            Contactez notre support
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="text-center">
          <p>
            &copy; {new Date().getFullYear()} EasyPlan. Tous droits réservés.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
