import React, { useState } from "react";
import "./WorkHoursCalculator.css";
import { FaStar } from "react-icons/fa";

function WorkHoursCalculator() {
  const [mode, setMode] = useState("daily"); // Mode pour calculer les heures quotidiennes, hebdomadaires ou annuelles
  const [startHour, setStartHour] = useState(9);
  const [startMinute, setStartMinute] = useState(30);
  const [endHour, setEndHour] = useState(18);
  const [endMinute, setEndMinute] = useState(0);
  const [breakHour, setBreakHour] = useState(1);
  const [breakMinute, setBreakMinute] = useState(0);
  const [dailyTotalHours, setDailyTotalHours] = useState(""); // Total quotidien
  const [weeklyHours, setWeeklyHours] = useState(40); // Heures par semaine pour le calcul annuel
  const [vacationWeeks, setVacationWeeks] = useState(5); // Semaines de congés
  const [annualHours, setAnnualHours] = useState(""); // Total annuel
  const [workDaysPerWeek, setWorkDaysPerWeek] = useState(5); // Nombre de jours de travail par semaine
  const [weeklyTotalHours, setWeeklyTotalHours] = useState(""); // Total hebdomadaire
  const [rating, setRating] = useState(0);

  // Heures de travail par jour pour chaque jour de la semaine
  const [workHoursPerDay, setWorkHoursPerDay] = useState({
    Lundi: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      breakHour: 1,
      breakMinute: 0,
    },
    Mardi: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      breakHour: 1,
      breakMinute: 0,
    },
    Mercredi: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      breakHour: 1,
      breakMinute: 0,
    },
    Jeudi: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      breakHour: 1,
      breakMinute: 0,
    },
    Vendredi: {
      startHour: 9,
      startMinute: 0,
      endHour: 17,
      endMinute: 0,
      breakHour: 1,
      breakMinute: 0,
    },
    Samedi: {
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
      breakHour: 0,
      breakMinute: 0,
    }, // weekend non travaillé par défaut
    Dimanche: {
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
      breakHour: 0,
      breakMinute: 0,
    }, // weekend non travaillé par défaut
  });

  const handleChange = (day, field, value) => {
    setWorkHoursPerDay({
      ...workHoursPerDay,
      [day]: { ...workHoursPerDay[day], [field]: parseInt(value) },
    });
  };

  const calculateDailyWorkHours = () => {
    const start = startHour * 60 + startMinute;
    const end = endHour * 60 + endMinute;
    const breakTime = breakHour * 60 + breakMinute;
    const workMinutes = end - start - breakTime;
    const hours = Math.floor(workMinutes / 60);
    const minutes = workMinutes % 60;
    setDailyTotalHours(`${hours}h ${minutes}min`);
  };

  const calculateWeeklyWorkHours = () => {
    let totalMinutes = 0;
    Object.keys(workHoursPerDay).forEach((day) => {
      const {
        startHour,
        startMinute,
        endHour,
        endMinute,
        breakHour,
        breakMinute,
      } = workHoursPerDay[day];
      if (startHour === 0 && endHour === 0) return; // Ne calcule pas les jours non travaillés

      const start = startHour * 60 + startMinute;
      const end = endHour * 60 + endMinute;
      const breakTime = breakHour * 60 + breakMinute;

      totalMinutes += end - start - breakTime;
    });

    const weeklyHours = Math.floor(totalMinutes / 60);
    const weeklyMinutes = totalMinutes % 60;
    setWeeklyTotalHours(`${weeklyHours}h ${weeklyMinutes}min`);
  };

  const calculateAnnualWorkHours = () => {
    const weeksPerYear = 52;
    const workWeeks = weeksPerYear - vacationWeeks;
    const totalAnnualHours = weeklyHours * workWeeks;
    setAnnualHours(`${totalAnnualHours} heures`);
  };

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  return (
    <div className="calculator-container">
      <h1 className="outil">
        Calcul heures de travail | Outil en ligne et gratuit
      </h1>

      <div className="option-buttons">
        <button className="option-button" onClick={() => setMode("daily")}>
          Heures de travail en une journée
        </button>
        <button className="option-button" onClick={() => setMode("weekly")}>
          Heures de travail hebdomadaires
        </button>
        <button className="option-button" onClick={() => setMode("annual")}>
          Heures de travail à l'année
        </button>
      </div>

      {mode === "daily" && (
        <>
          <div className="time-input">
            <label>Heure de début de travail</label>
            <input
              type="number"
              value={startHour}
              onChange={(e) => setStartHour(parseInt(e.target.value))}
            />
            h
            <input
              type="number"
              value={startMinute}
              onChange={(e) => setStartMinute(parseInt(e.target.value))}
            />
            min
          </div>

          <div className="time-input">
            <label>Heure de fin de travail</label>
            <input
              type="number"
              value={endHour}
              onChange={(e) => setEndHour(parseInt(e.target.value))}
            />
            h
            <input
              type="number"
              value={endMinute}
              onChange={(e) => setEndMinute(parseInt(e.target.value))}
            />
            min
          </div>

          <div className="time-input">
            <label>Temps de pause</label>
            <input
              type="number"
              value={breakHour}
              onChange={(e) => setBreakHour(parseInt(e.target.value))}
            />
            h
            <input
              type="number"
              value={breakMinute}
              onChange={(e) => setBreakMinute(parseInt(e.target.value))}
            />
            min
          </div>

          <button
            className="calculate-button"
            onClick={calculateDailyWorkHours}
          >
            Calculer les heures de travail
          </button>

          <div className="result">
            <label>Total d’heures de travail de la journée</label>
            <p>{dailyTotalHours || "-- h -- min"}</p>
          </div>
        </>
      )}

      {mode === "weekly" && (
        <>
          <div className="weekly-input">
            {Object.keys(workHoursPerDay).map((day) => (
              <div key={day} className="time-input">
                <label>{day}</label>
                <input
                  type="number"
                  value={workHoursPerDay[day].startHour}
                  onChange={(e) =>
                    handleChange(day, "startHour", e.target.value)
                  }
                />
                h
                <input
                  type="number"
                  value={workHoursPerDay[day].startMinute}
                  onChange={(e) =>
                    handleChange(day, "startMinute", e.target.value)
                  }
                />
                min
                <span>à</span>
                <input
                  type="number"
                  value={workHoursPerDay[day].endHour}
                  onChange={(e) => handleChange(day, "endHour", e.target.value)}
                />
                h
                <input
                  type="number"
                  value={workHoursPerDay[day].endMinute}
                  onChange={(e) =>
                    handleChange(day, "endMinute", e.target.value)
                  }
                />
                min
                <span>Pause</span>
                <input
                  type="number"
                  value={workHoursPerDay[day].breakHour}
                  onChange={(e) =>
                    handleChange(day, "breakHour", e.target.value)
                  }
                />
                h
                <input
                  type="number"
                  value={workHoursPerDay[day].breakMinute}
                  onChange={(e) =>
                    handleChange(day, "breakMinute", e.target.value)
                  }
                />
                min
              </div>
            ))}
          </div>

          <button
            className="calculate-button"
            onClick={calculateWeeklyWorkHours}
          >
            Calculer les heures de travail hebdomadaires
          </button>

          <div className="result">
            <label>Total d’heures de travail hebdomadaires</label>
            <p>{weeklyTotalHours || "-- h -- min"}</p>
          </div>
        </>
      )}

      {mode === "annual" && (
        <>
          <div className="time-input">
            <label>Nombre d’heures par semaine</label>
            <input
              type="number"
              value={weeklyHours}
              onChange={(e) => setWeeklyHours(parseInt(e.target.value))}
            />
            h
          </div>

          <div className="time-input">
            <label>Semaines de congés</label>
            <input
              type="number"
              value={vacationWeeks}
              onChange={(e) => setVacationWeeks(parseInt(e.target.value))}
            />
            semaines
          </div>

          <button
            className="calculate-button"
            onClick={calculateAnnualWorkHours}
          >
            Calculer les heures de travail annuelles
          </button>

          <div className="result">
            <label>Total d’heures de travail en un an</label>
            <p>{annualHours || "-- heures"}</p>
          </div>
        </>
      )}

      <p className="satisfaction">Êtes-vous satisfait par notre outil ?</p>

      {/* Système d'évaluation par étoiles */}
      <div className="star-rating">
        {[...Array(5)].map((_, index) => (
          <FaStar
            key={index}
            size={30}
            onClick={() => handleStarClick(index)}
            color={index < rating ? "#ffc107" : "#e4e5e9"}
            style={{ cursor: "pointer" }}
          />
        ))}
      </div>

      {rating > 0 && (
        <p>
          Merci pour votre évaluation de {rating} étoile{rating > 1 ? "s" : ""}{" "}
          !
        </p>
      )}
    </div>
  );
}

export default WorkHoursCalculator;
