import React from "react";

import { FaUserCircle } from "react-icons/fa";

function ParticularLogin() {
  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">
          <FaUserCircle></FaUserCircle>
        </h2>
        <form>
          <div className="form-group">
            <label htmlFor="email">Adresse e-mail</label>
            <input
              type="email"
              id="email"
              placeholder="Entrez votre e-mail"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Entrez votre mot de passe"
              required
            />
          </div>
          <button type="submit" className="login-button">
            Se connecter
          </button>
        </form>
      </div>
    </div>
  );
}

export default ParticularLogin;
