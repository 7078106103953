import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

import { FaHome, FaTachometerAlt, FaUserFriends } from "react-icons/fa";

function Navbar() {
  return (
    <nav className="bg-gray-800 text-white p-4">
      <ul className="flex justify-around">
        <li>
          <Link to="/" className="flex items-center space-x-2">
            <FaHome />
            <span>Accueil</span>
          </Link>
        </li>
        <li>
          <Link
            to="/work-hours-calculator"
            className="flex items-center space-x-2"
          >
            <FaUserFriends />
            <span>Calculateur d'heures</span>
          </Link>
        </li>
        <li>
          <Link to="/company" className="flex items-center space-x-2">
            <FaTachometerAlt />
            <span>Demo</span>
          </Link>
        </li>
        <li>
          <Link to="/particular" className="flex items-center space-x-2">
            <FaUserFriends />
            <span>Entreprise</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
