import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Company from "./pages/Company";
import Particular from "./pages/Particular";
import WorkHoursCalculator from "./pages/WorkHoursCalculator";

import "./index.css";
import "./App.css";
import "./pages/particular/ParticularLogin.css";
import "./pages/WorkHoursCalculator.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/company" element={<Company />} />
          <Route path="/particular" element={<Particular />} />
          <Route
            path="/work-hours-calculator"
            element={<WorkHoursCalculator />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
