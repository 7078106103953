import React from "react";
import ParticularLogin from "./particular/ParticularLogin";

function Particular() {
  const isauthenticated = false;
  return isauthenticated ? (
    <div className="flex flex-col items-center justify-center p-8">
      <h1 className="text-3xl font-bold mb-4">Gestion des Employés</h1>
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-xl font-semibold">Liste des employés</h2>
        <table className="min-w-full mt-4 table-auto">
          <thead>
            <tr>
              <th className="px-4 py-2">Nom</th>
              <th className="px-4 py-2">Poste</th>
              <th className="px-4 py-2">Heures travaillées</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">Jean Dupont</td>
              <td className="border px-4 py-2">Serveur</td>
              <td className="border px-4 py-2">40h</td>
              <td className="border px-4 py-2">
                <button className="text-blue-500">Modifier</button>
                <button className="text-red-500 ml-4">Supprimer</button>
              </td>
            </tr>
            {/* Ajouter d'autres lignes d'employés ici */}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <ParticularLogin />
  );
}

export default Particular;
