import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Dashboard.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [planningDetails, setPlanningDetails] = useState({
    shifts: [
      {
        name: "Pierre",
        start: "09:00",
        end: "17:00",
        break: 30,
        totalHours: 7.5,
        date: "2024-11-01",
      },
      {
        name: "Marie",
        start: "14:00",
        end: "22:00",
        break: 30,
        totalHours: 7.5,
        date: "2024-11-02",
      },
    ],
  });

  const [newShift, setNewShift] = useState({
    name: "",
    start: "",
    end: "",
    break: "",
    date: "",
  });

  const [calendarEvents, setCalendarEvents] = useState([
    {
      title: "Pierre - Shift",
      start: "2024-11-01T09:00:00",
      end: "2024-11-01T17:00:00",
      description: "Shift normal",
    },
    {
      title: "Marie - Shift",
      start: "2024-11-02T14:00:00",
      end: "2024-11-02T22:00:00",
      description: "Shift de nuit",
    },
  ]);

  const [selectedPeriod, setSelectedPeriod] = useState({ start: "", end: "" });
  const [successMessage, setSuccessMessage] = useState("");

  const users = ["Pierre", "Marie", "Jean", "Lucie"]; // Liste des utilisateurs

  const addShift = () => {
    const { start, end, break: breakTime, date, name } = newShift;

    if (!start || !end || !date || !name) {
      setSuccessMessage("Tous les champs doivent être remplis !");
      setTimeout(() => setSuccessMessage(""), 3000);
      return;
    }

    const startTime = new Date(`2021-01-01T${start}:00`);
    const endTime = new Date(`2021-01-01T${end}:00`);
    const breakTimeInHours = breakTime / 60;

    if (startTime >= endTime) {
      setSuccessMessage("L'heure de fin doit être après l'heure de début !");
      setTimeout(() => setSuccessMessage(""), 3000);
      return;
    }

    const totalWorkHours = (endTime - startTime) / 3600000 - breakTimeInHours;

    const updatedShifts = [
      ...planningDetails.shifts,
      { ...newShift, totalHours: totalWorkHours },
    ];

    setPlanningDetails({
      shifts: updatedShifts,
    });

    setCalendarEvents([
      ...calendarEvents,
      {
        title: `${name} - Shift`,
        start: `${date}T${start}:00`,
        end: `${date}T${end}:00`,
        description: `Shift de ${name}`,
      },
    ]);

    setNewShift({ name: "", start: "", end: "", break: "", date: "" });
    setSuccessMessage("Shift ajouté avec succès !");
    setTimeout(() => setSuccessMessage(""), 3000);
  };

  const filterShiftsByPeriod = () => {
    const { start, end } = selectedPeriod;

    if (!start || !end) {
      setSuccessMessage("Veuillez sélectionner une période valide.");
      setTimeout(() => setSuccessMessage(""), 3000);
      return [];
    }

    const startDate = new Date(start);
    const endDate = new Date(end);

    return planningDetails.shifts.filter((shift) => {
      const shiftDate = new Date(shift.date);
      return shiftDate >= startDate && shiftDate <= endDate;
    });
  };

  const exportToCSV = () => {
    const filteredShifts = filterShiftsByPeriod();
    if (filteredShifts.length === 0) {
      setSuccessMessage("Aucun shift trouvé pour cette période.");
      setTimeout(() => setSuccessMessage(""), 3000);
      return;
    }

    const csvRows = [
      [
        "Nom",
        "Date",
        "Heure d'entrée",
        "Heure de sortie",
        "Pause (min)",
        "Heures Totales",
      ],
      ...filteredShifts.map((shift) => [
        shift.name,
        shift.date,
        shift.start,
        shift.end,
        shift.break,
        shift.totalHours.toFixed(2),
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "planning.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setSuccessMessage("Le fichier CSV a été exporté avec succès !");
    setTimeout(() => setSuccessMessage(""), 3000);
  };

  return (
    <div className="dashboard">
      {successMessage && <div className="success-toast">{successMessage}</div>}

      <h3 className="dashboard-title">Création des plannings</h3>

      <div className="planning-section"></div>

      <div className="shift-form">
        <select
          value={newShift.name}
          onChange={(e) => setNewShift({ ...newShift, name: e.target.value })}
        >
          <option value="">Sélectionnez un employé</option>
          {users.map((user, index) => (
            <option key={index} value={user}>
              {user}
            </option>
          ))}
        </select>
        <input
          type="date"
          value={newShift.date}
          onChange={(e) => setNewShift({ ...newShift, date: e.target.value })}
        />
        <input
          type="time"
          value={newShift.start}
          onChange={(e) => setNewShift({ ...newShift, start: e.target.value })}
        />
        <input
          type="time"
          value={newShift.end}
          onChange={(e) => setNewShift({ ...newShift, end: e.target.value })}
        />
        <input
          type="number"
          placeholder="Pause en minutes"
          value={newShift.break}
          onChange={(e) => setNewShift({ ...newShift, break: e.target.value })}
        />
        <button onClick={addShift}>Ajouter Shift</button>
      </div>

      <div className="planning-list">
        <h3>Liste des Shifts</h3>
        <table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Heure d'entrée</th>
              <th>Heure de sortie</th>
              <th>Pause (min)</th>
              <th>Heures Totales</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {planningDetails.shifts.map((shift, index) => (
              <tr key={index}>
                <td>{shift.name}</td>
                <td>{shift.start}</td>
                <td>{shift.end}</td>
                <td>{shift.break}</td>
                <td>{shift.totalHours.toFixed(2)} h</td>
                <td>{shift.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2>Filtrer et Exporter les Plannings</h2>
      <div className="filter-form">
        <input
          type="date"
          value={selectedPeriod.start}
          onChange={(e) =>
            setSelectedPeriod({ ...selectedPeriod, start: e.target.value })
          }
        />
        <input
          type="date"
          value={selectedPeriod.end}
          onChange={(e) =>
            setSelectedPeriod({ ...selectedPeriod, end: e.target.value })
          }
        />
        <button onClick={exportToCSV}>Exporter la période en CSV</button>
      </div>

      <div className="calendar-section">
        <h2>Calendrier des Shifts</h2>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={calendarEvents}
        />
      </div>
    </div>
  );
};

export default Dashboard;
