import React from "react";
import Dashboard from "../pages/company/src/components/Dashboard/Dashboard";
import "./company/src/components/Dashboard/Dashboard.css";
function Company() {
  return (
    <div>
      <Dashboard />
    </div>
  );
}

export default Company;
